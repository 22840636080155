import React, { Component } from 'react';
import { observer, globalContext } from '@digiforce/dvd-editor-core';
import { BuiltinSimulatorHost, BuiltinSimulatorProps } from './host';
import { BemTools } from './bem-tools';
import { Project } from '../project';
import './host.less';

/*
Simulator Simulator, replaceable parts, with protocol constraints, container containing canvas, use case: when Canvas size changes, used to center or position Canvas
   Canvas (DeviceShell) device shell, simulated by background images, changing width, height and positioning CanvasViewport through device preset styles
   The width and height of the CanvasViewport page layout scene cannot overflow the Canvas area
   Content(Shell) The outer layer of the content, the width and height are close to the CanvasViewport, the border is disabled, the margin is disabled
   BemTools auxiliary display layer, the initial relative content position is 0,0, close to the Canvas, and change the relative position according to the content scroll position
*/

type SimulatorHostProps = BuiltinSimulatorProps & {
  project: Project;
  onMount?: (host: BuiltinSimulatorHost) => void;
};

export class BuiltinSimulatorHostView extends Component<SimulatorHostProps> {
  readonly host: BuiltinSimulatorHost;

  constructor(props: any) {
    super(props);
    const { project, onMount } = this.props;
    this.host = (project.simulator as BuiltinSimulatorHost) || new BuiltinSimulatorHost(project);
    this.host.setProps(this.props);
    onMount?.(this.host);
  }

  shouldComponentUpdate(nextProps: BuiltinSimulatorProps) {
    this.host.setProps(nextProps);
    return false;
  }

  render() {
    return (
      <div className="lc-simulator">
        {/* progressing.visible ? <PreLoaderView /> : null */}
        <Canvas host={this.host} />
      </div>
    );
  }
}

@observer
class Canvas extends Component<{ host: BuiltinSimulatorHost }> {
  render() {
    const sim = this.props.host;
    let className = 'lc-simulator-canvas';
    const { canvas = {}, viewport = {} } = sim.deviceStyle || {};
    if (sim.deviceClassName) {
      className += ` ${sim.deviceClassName}`;
    } else if (sim.device) {
      className += ` lc-simulator-device-${sim.device}`;
    }

    return (
      <div className={className} style={canvas}>
        <div ref={(elmt) => sim.mountViewport(elmt)} className="lc-simulator-canvas-viewport" style={viewport}>
          <BemTools host={sim} />
          <Content host={sim} />
        </div>
      </div>
    );
  }
}

@observer
class Content extends Component<{ host: BuiltinSimulatorHost }> {
  state = {
    disabledEvents: false,
  };

  private dispose?: () => void;

  componentDidMount() {
    const editor = globalContext.get('editor');
    const onEnableEvents = (type: boolean) => {
      this.setState({
        disabledEvents: type,
      });
    };

    editor.on('designer.builtinSimulator.disabledEvents', onEnableEvents);

    this.dispose = () => {
      editor.removeListener('designer.builtinSimulator.disabledEvents', onEnableEvents);
    };
  }

  componentWillUnmount() {
    this.dispose?.();
  }

  render() {
    const sim = this.props.host;
    const { disabledEvents } = this.state;
    const { viewport } = sim;
    const frameStyle: any = {
      transform: `scale(${viewport.scale})`,
      height: viewport.contentHeight,
      width: viewport.contentWidth,
    };
    if (disabledEvents) {
      frameStyle.pointerEvents = 'none';
    }

    return (
      <div className="lc-simulator-content">
        <iframe
          name="SimulatorRenderer"
          className="lc-simulator-content-frame"
          style={frameStyle}
          ref={(frame) => sim.mountContentFrame(frame)}
        />
      </div>
    );
  }
}

import { createIntl } from '@digiforce/dvd-editor-core';
import en_US from './en-US.json';
import vi_VN from './vi-VN.json';

const { intl, intlNode, getLocale, setLocale } = createIntl({
  'en-US': en_US,
  'vi-VN': vi_VN,
});

export { intl, intlNode, getLocale, setLocale };

import React, { createElement, useEffect } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { globalContext, Editor, engineConfig, EngineOptions, EngineConfig } from '@digiforce/dvd-editor-core';
import {
  Designer,
  LowCodePluginManager,
  ILowCodePluginContext,
  PluginPreference,
  TransformStage,
  ILowCodeRegisterOptions,
} from '@digiforce/dvd-designer';
import {
  Skeleton as InnerSkeleton,
  SettingsPrimaryPane,
  registerDefaults,
} from '@digiforce/dvd-editor-skeleton';
import { Spin } from 'antd'
import Outline, { OutlineBackupPane, getTreeMaster } from '@digiforce/dvd-plugin-outline-pane';
import DesignerPlugin from '@digiforce/dvd-plugin-designer';
import { Hotkey, Project, Skeleton, Setters, Material, Event } from '@digiforce/dvd-shell';
import { getLogger, isPlainObject } from '@digiforce/dvd-utils';
import './modules/live-editing';
import utils from './modules/utils';
import * as editorCabin from './modules/editor-cabin';
import getSkeletonCabin from './modules/skeleton-cabin';
import getDesignerCabin from './modules/designer-cabin';
import classes from './modules/classes';
import symbols from './modules/symbols';
import { IEditor } from '@digiforce/dvd-types';
import { ILowCodePluginConfig } from './modules/designer-types';
export * from './modules/editor-types';
export * from './modules/skeleton-types';
export * from './modules/designer-types';
export * from './modules/lowcode-types';

// @ts-ignore webpack Define variable
export const version = VERSION_PLACEHOLDER;
engineConfig.set('ENGINE_VERSION', version);

registerDefaults();
export const logger = getLogger({ level: 'warn', bizName: 'common' });

type handler = (ctx: ILowCodePluginContext, options: any) => ILowCodePluginConfig;
interface PluginHandler extends handler {
  pluginName?: string
}

class DesignerSingleton {
  private editor: Editor
  private skeleton: Skeleton
  private plugins: LowCodePluginManager
  private project: Project
  private setters: Setters
  private material: Material
  private config: EngineConfig
  private event: Event
  private hotkey: Hotkey
  private common: any
  private innerSkeleton: any
  private Workbench: (props: any) => JSX.Element
  private registeredPlugins: PluginHandler[] = []
  private defaultPlugins: PluginHandler[] = []
  private designerCabin: any
  private skeletonCabin: any
  private designer: Designer
  private engineOptions: string[]
  private engineOptionsDefault: any = {}
  private designerDomId: string = 'digiforce-designer-container'
  private mounted = false
  
  constructor() {
    const self = this;
    const componentMetaParser = (ctx: ILowCodePluginContext) => {
      return {
        init() {
          self.editor.onGot('assets', (assets: any) => {
            const { components = [] } = assets;
            self.designer.buildComponentMetasMap(components);
          });
        },
      };
    }
    componentMetaParser.pluginName = '___component_meta_parser___';
  
    const setterRegistry = (ctx: ILowCodePluginContext) => {
      return {
        init() {
          if (engineConfig.get('disableDefaultSetters')) return;
          const builtinSetters = require('@digiforce/dvd-engine-ext')?.setters;
          if (builtinSetters) {
            ctx.setters.registerSetter(builtinSetters);
          }
        },
      };
    };
    setterRegistry.pluginName = '___setter_registry___';
  
    const defaultPanelRegistry = (ctx: ILowCodePluginContext) => {
      return {
        init() {
          self.skeleton.add({
            area: 'mainArea',
            name: 'designer',
            type: 'Widget',
            content: DesignerPlugin,
          });
          if (!engineConfig.get('disableDefaultSettingPanel')) {
            self.skeleton.add({
              area: 'rightArea',
              name: 'settingsPane',
              type: 'Panel',
              content: SettingsPrimaryPane,
              props: {
                ignoreRoot: true,
              },
            });
          }
  
          // by default in float area;
          let isInFloatArea = true;
          const hasPreferenceForOutline = self.editor
            ?.getPreference()
            ?.contains('outline-pane-pinned-status-isFloat', 'skeleton');
          if (hasPreferenceForOutline) {
            isInFloatArea = self.editor
              ?.getPreference()
              ?.get('outline-pane-pinned-status-isFloat', 'skeleton');
          }
  
          self.skeleton.add({
            area: 'leftArea',
            name: 'outlinePane',
            type: 'PanelDock',
            content: Outline,
            panelProps: {
              area: isInFloatArea ? 'leftFloatArea' : 'leftFixedArea',
              keepVisibleWhileDragging: true,
              ...engineConfig.get('defaultOutlinePaneProps'),
            },
          });
          self.skeleton.add({
            area: 'rightArea',
            name: 'backupOutline',
            type: 'Panel',
            props: {
              condition: () => {
                return self.designer.dragon.dragging && !getTreeMaster(self.designer).hasVisibleTreeBoard();
              },
            },
            content: OutlineBackupPane,
          });
        },
      };
    };
    defaultPanelRegistry.pluginName = '___default_panel___';

    this.defaultPlugins.push(componentMetaParser)
    this.defaultPlugins.push(setterRegistry)
    this.defaultPlugins.push(defaultPanelRegistry)
    
    this.common = {
      utils,
      objects: {
        TransformStage,
      },
      editorCabin,
      get designerCabin() {
        return self.designerCabin
      },
      get skeletonCabin() {
        return self.skeletonCabin
      },
    };
    this.setters = new Setters();
    this.startup()
  }

  private async _registerPlugins() {
    for (const plugin of this.defaultPlugins) {
      await this.plugins.register(plugin)
    }
    for (const plugin of this.registeredPlugins) {
      await this.plugins.register(plugin)
    }
  }

  public async startup() {
    this.editor = new Editor();
    if (!globalContext.has('editor')) {
      globalContext.register(this.editor, Editor);
      globalContext.register(this.editor, 'editor');
    }
    else {
      globalContext.replace(Editor, this.editor);
      globalContext.replace('editor', this.editor);
    }

    this.innerSkeleton = new InnerSkeleton(this.editor);
    this.editor.set('skeleton' as any, this.innerSkeleton);

    this.designer = new Designer({ editor: this.editor });
    this.editor.set('designer' as any, this.designer);

    this.plugins = new LowCodePluginManager(this.editor).toProxy();
    this.editor.set('plugins' as any, this.plugins);

    const { project: innerProject } = this.designer;
    this.skeletonCabin = getSkeletonCabin(this.innerSkeleton);
    this.Workbench = this.skeletonCabin.Workbench;

    this.hotkey = new Hotkey();
    this.project = new Project(innerProject);
    this.skeleton = new Skeleton(this.innerSkeleton);
    this.material = new Material(this.editor);
    this.config = engineConfig;
    this.event = new Event(this.editor, { prefix: 'common' });
    
    this.designerCabin = getDesignerCabin(this.editor);
    this.registeredPlugins = [];
  }

  private async destroy() {
    engineConfig.setConfig(this.engineOptionsDefault);
    await this.unregisterPlugins();
  }

  private async unregisterPlugins() {
    for (const plugin of this.defaultPlugins) {
      await this.plugins.delete(plugin.pluginName as string)
    }
    for (const plugin of this.registeredPlugins) {
      await this.plugins.delete(plugin.pluginName as string)
    }
  }
  
  public get(prop: string) {
    return (this as any)[prop];
  }

  public setOptions(options: EngineOptions = { requestHandlersMap: { }}) {
    this.engineOptions = Object.keys(options)
    this.engineOptionsDefault = {}
    this.engineOptions.map(key => {
      this.engineOptionsDefault[key] = engineConfig.get(key)
    })
    engineConfig.setEngineOptions(options);
  }

  public async mount(
    container?: HTMLElement,
    pluginPreference?: PluginPreference,
  ) {
    if (!this.mounted) {
      container = (container || document.getElementById(this.designerDomId)) as any
      if (!container) {
        container = document.createElement('div')
        document.body.append(container);
      }
      container.style.height = '100%'
      if (!container.id) container.id = this.designerDomId
      else this.designerDomId = container.id
      await this._registerPlugins()
      await this.plugins.init(pluginPreference);
      
      render(
        createElement(this.Workbench, {
          skeleton: this.innerSkeleton,
          className: 'engine-main',
          topAreaItemClassName: 'engine-actionitem',
        }),
        container,
      );
      this.mounted = true
    }
    else {
      console.warn('Only one instance of designer can be mounted at a time')
    }
  }

  public async umount() {
    await this.destroy()
    if (document.getElementById(this.designerDomId)) {
      unmountComponentAtNode(document.getElementById(this.designerDomId) as any)
    }
    this.mounted = false
  }
}

let designerSingleton = new DesignerSingleton()
export const mount = designerSingleton.mount.bind(designerSingleton)
export const umount = designerSingleton.umount.bind(designerSingleton)
export const startup = designerSingleton.startup.bind(designerSingleton)
export const setOptions = designerSingleton.setOptions.bind(designerSingleton)
export const skeleton = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('skeleton')[prop]
  }
})
export const plugins = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('plugins')[prop]
  }
})
export const project = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('project')[prop]
  }
})
export const setters = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('setters')[prop]
  }
})
export const material = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('material')[prop]
  }
})
export const config = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('config')[prop]
  }
})
export const event = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('event')[prop]
  }
})
export const hotkey = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('hotkey')[prop]
  }
})
export const common = new Proxy({}, {
  get(target, prop, receiver) {
    return designerSingleton.get('common')[prop]
  }
})
export { event as editor }